<template>
  <div>
    <div class="taglist" v-for="tag in currentTags" :key="tag">
      <b-button
        variant="secondary"
        pill
        lg="4"
        class="pb-2"
        @click="openCategory(tag)"
        >{{ tag }}</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "tags",
  props: {
    blogTags: String,
  },
  data() {
    return {
      currentTags: [],
    };
  },
  watch: {
    blogTags: function (newVal) {
      this.currentTags = newVal.split(" ");
      this.currentTags = this.currentTags.filter((x) => x !== "");
    },
  },
  methods: {
    openCategory(category) {
      localStorage.setItem("category", category);
      this.$router
        .push({
          name: "Category",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.taglist {
  display: inline-block;
  padding: 2px;
}
</style>
